import { Component, Input, OnChanges, OnInit, Optional, Pipe, PipeTransform, Self, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { isObject, isString } from '@shared/helpers/functions';
import { utils } from '@shared/helpers/utils';
import { QueryService } from "@shared/services/query.service";

@Pipe({
  name: 'filterPipe'
})
export class FilterPipe implements PipeTransform {
  transform(value: any[], args?: string): any {
    if (!args) {
      return value;
    }
    return value.filter(item => item.name.toLowerCase().indexOf(args.toLowerCase()) > -1);
  }
}

@Pipe({
  name: 'valuesPipe'
})
export class ValuesPipe implements PipeTransform {
  transform(value: any[], args?: any[], element = 'value'): any {
    if (!value || !args || !args.length) {
      return value;
    }
    return value.filter(elem => {
      return !args.find(item => item[element] === elem[element]);
    });
  }
}

@Pipe({
  name: 'displayPipe'
})
export class DisplayPipe implements PipeTransform {
  transform(value: string | object, display?: string): any {
    return isObject(value) ? value[display] : value;
  }
}

@Component({
  selector: ' form-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements ControlValueAccessor, OnChanges, OnInit {

  @Input() extra: string;
  @Input() display = 'named';
  @Input() element = 'value';
  @Input() readonly: boolean;
  @Input() isString: boolean;
  @Input() filtered: boolean;
  @Input() required: boolean;
  @Input() multiple: string;
  @Input() placeholder: string;
  @Input() items: any[];
  @Input() filter: boolean;
  @Input() filterQuerySearchKey = this.element;

  queryStr: string;
  uniqueId: string;
  disabled: boolean;
  @Input() value: any;
  @Input() withoutClear: boolean;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(@Self() @Optional() public control: NgControl,
              public queryService: QueryService) {
    if (control) {
      control.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.uniqueId = utils.makeId();
    this.setFilterValueFromQuery();
  }



  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['items']) {
    //   this.writeValue(this.value);
    // }
    if (changes?.value?.currentValue) {

      this.writeValue(changes.value.currentValue);
    }
  }

  setFilterValueFromQuery() {
    if (this.filter) {
      const queryValue = this.queryService.getValueFromQueryByName(this.control.name);
      const shortValue = this.value;
      if (queryValue || shortValue) {
        this.writeValue(queryValue || shortValue);
      }
    }
  }

  writeValue(value?: any) {
    if (value && this.items && isString(value)) {
      value = this.items.find((item) => item[this.element] === value);
    }
    this.value = value;

    this.onChange((this.isString && value) ? value[this.element] : value);
  }

  writeMultiValue(value: any, checked: boolean) {

  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  onHidden() {
    this.control.control.markAsTouched();
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  get isValue(): boolean {
    return Array.isArray(this.value) ? this.value.length : this.value;
  }

  get errors(): any {
    return this.control && this.control.errors || false;
  }

  get touched(): any {
    return this.control && this.control.touched || false;
  }

}
