import { NgModule } from '@angular/core';
import { CommonModule, LowerCasePipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxFilesizeModule } from 'ngx-filesize';
import { DATE_FORMAT } from '@shared/helpers/constants';

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    showWeekNumbers: false,
    dateInputFormat: DATE_FORMAT,
    containerClass: 'theme-default',
    customTodayClass: 'today',
    adaptivePosition: true,
    // useUtc: false,
  });
}

import { InputComponent } from './input/input.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileLiveUploadComponent } from './file-live-upload/file-live-upload.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { DatepickerComponent } from '@shared/modules/forms/datepicker/datepicker.component';
import { RadioComponent } from './radio/radio.component';
import { SelectComponent, DisplayPipe, FilterPipe, ValuesPipe } from './select/select.component';
import { TypeaheadComponent } from './typeahead/typeahead.component';
import { TextareaComponent } from './textarea/textarea.component';

@NgModule({
  declarations: [
    InputComponent,
    SelectComponent,
    CheckboxComponent,
    RadioComponent,
    FileUploadComponent,
    FileLiveUploadComponent,
    ImageUploadComponent,
    TypeaheadComponent,
    DatepickerComponent,
    SelectComponent,
    TypeaheadComponent,
    FilterPipe,
    ValuesPipe,
    DisplayPipe,
    TextareaComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    NgxFilesizeModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    InputComponent,
    SelectComponent,
    CheckboxComponent,
    RadioComponent,
    FileUploadComponent,
    FileLiveUploadComponent,
    ImageUploadComponent,
    TypeaheadComponent,
    DatepickerComponent,
    ValuesPipe,
    TextareaComponent,
  ],
  providers: [
    { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
    MaskPipe,
    LowerCasePipe,
    TitleCasePipe,
    UpperCasePipe,
  ]
})
export class FormModule { }
